import { graphql, navigate } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import * as React from "react";
import { useMemo, useState } from "react";
import Button from "../components/Button";
import {
  MainContainer,
  PageContainer,
  SkewMainContainer,
} from "../components/Container";
import Footer, { WechatModal } from "../components/Footer";
import Header, { ThemeEnum } from "../components/Header";
import Metadata from "../components/Metadata";
import SearchInput from "../components/SearchInput";
import SkewGradientBg from "../components/SkewGradientBg";
import {
  Contact01,
  Contact02,
  Contact03,
  Contact04,
  Contact05,
  Contact06,
  ContactChatCover,
  ContactRightArrow,
  ContactUs,
} from "../images";
import { usePageMetadata } from "../metadata/page";
import { PageTitleWithIcon } from "./price";

const ContactUsPage = () => {
  const { t } = useTranslation();
  const { contactUs } = usePageMetadata();
  const [wechatVisible, setWechatVisible] = useState(false);
  const lists = useMemo(
    () => [
      {
        title: t("标注服务"),
        desc: t("我们可以为您的算法模型提供高精度的训练数据。"),
        icon: <Contact04 />,
        linkUrl: "/contact",
        linkText: t("填写需求表格"),
      },
      {
        title: t("私有化部署"),
        desc: t("标注工具可以部署在您的私有化服务器上，解决您的数据安全顾虑。"),
        icon: <Contact02 />,
        linkUrl: "mailto:contacts@stardust.ai",
        linkText: t("contacts@stardust.ai"),
      },
      {
        title: t("标注团队入驻"),
        desc: t("我们希望与更多优秀的供应商团队，建立深度合作关系"),
        icon: <Contact03 />,
        linkUrl: "http://stardustai.mikecrm.com/m4EbYrs",
        linkText: t("团队入驻申请"),
        target: "_blank",
      },
      {
        title: t("常规需求咨询"),
        desc: t("媒体联系，投资人关系、技术咨询等"),
        icon: <Contact01 />,
        linkUrl: "mailto:hello@stardust.ai",
        linkText: t("hello@stardust.ai"),
      },
      {
        title: t("微信公众号"),
        desc: t("关注我们的微信公众号"),
        icon: <Contact05 />,
        linkUrl: "",
        linkText: t("查看公众号二维码"),
        type: "wechat",
      },
      {
        title: t("星尘数据知乎"),
        desc: t("关注我们的知乎专栏。"),
        icon: <Contact06 />,
        linkUrl: "https://zhuanlan.zhihu.com/c_1148208696742977536",
        linkText: t("关注知乎专栏"),
        target: "_blank",
      },
    ],
    [t]
  );
  return (
    <PageContainer>
      <Header defaultTheme={ThemeEnum.dark} />
      <Metadata {...contactUs} />
      <SkewGradientBg id="gradient-contact-us" />
      <MainContainer>
        <PageTitleWithIcon title={t("联系我们")} icon={<ContactUs />} />
        <div className="flex flex-wrap relative">
          <div className="w-full sm:w-[460px]">
            <h2 className="text-2xl">{t("我们期待倾听您的需求")}</h2>
            <p className="mt-6 text-md disclaimer-color">
              {t("请与我们联系，将为您提供最专业的数据服务")}
            </p>
          </div>
          <div className="relative sm:ml-16 mt-8 sm:mt-0">
            <div className="sm:absolute z-30 sm:-mt-20 rounded-md">
              {/* <img
                  src={chat}
                  className="sm:w-[500px] w-full max-w-none"
                  alt=""
                /> */}
              <ContactChatCover className="sm:w-[553px] sm:h-[540px] w-full max-w-none scale-90" />
            </div>
          </div>
        </div>
        <div className="w-full sm:w-[378px] mt-12 sm:pb-12">
          <SearchInput />
          <div className="flex justify-between mt-7">
            <Button onClick={() => navigate("/contact")}>
              {t("联系销售")}
            </Button>
          </div>
        </div>
      </MainContainer>
      <SkewMainContainer fillClassName="bg-white">
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-8 pt-4">
          {lists.map((list, index) => (
            <a
              key={index}
              className="rounded-md overflow-hidden contact-us-card cursor-pointer"
              onClick={() => {
                if (list.type === "wechat") {
                  setWechatVisible(true);
                  return;
                }
                window.open(list.linkUrl, list.target);
              }}
            >
              <div className="flex flex-col items-center border-t border-r border-l p-12 rounded-t-md">
                {list.icon}
                <h3 className="search-caret-color text-xl mt-4 mb-6">
                  {list.title}
                </h3>
                <p className="text-center disclaimer-color">{list.desc}</p>
              </div>
              <div className="card-link-bg h-20 flex justify-center items-center">
                <p className="primary-text mr-2">
                  {list.linkText ?? list.linkUrl}
                </p>
                <ContactRightArrow fill="#493BFF" />
              </div>
            </a>
          ))}
        </div>
      </SkewMainContainer>
      <Footer />
      <WechatModal
        visible={wechatVisible}
        onCancel={() => setWechatVisible(false)}
      />
    </PageContainer>
  );
};
export default ContactUsPage;

export const query = graphql`
  query ContactUsPage($language: String!) {
    ...AllLangauages
  }
`;
